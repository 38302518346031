<template>
  <div>
    <sidebar :id="id" :menu="menu" :submenu="submenu" />
    <v-container fluid>
      <LoadData />
    </v-container>
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import LoadData from "../../components/Laporan/laporanInbound/loadData.vue"

export default {
  name: "Laporan_Inbound",

  components: { Sidebar, LoadData },

  metaInfo: {
    title: "Rhea Admin",
    titleTemplate: "%s - Laporan Inbound",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  data: () => ({
    id: "0",
    menu: "Laporan",
    submenu: " -> Transaksi Inbound",
  }),
};
</script>