<template>
    <div>
        <v-container fluid class="px-0">
            <v-card outlined elevation="3">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataInbound"
                        :loading="loading"
                        :items-per-page="itemsPerPage"
                        :page.sync="page"
                        hide-default-footer
                        dense
                    >
                        <template v-slot:top>
                            <v-toolbar flat dense class="mb-5">
                                <v-row dense>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="expiredDate"
                                            label="Expired Date"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listExpiredDate"
                                            item-value="expired_date"
                                            v-on:change="applyFilter"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .expired_date == ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.expired_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .expired_date == ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.expired_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="manufacturedDate"
                                            label="Manufactured Date"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listManufacturedDate"
                                            item-value="manufacturing_date"
                                            v-on:change="applyFilter"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .manufacturing_date ==
                                                            ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.manufacturing_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .manufacturing_date ==
                                                            ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.manufacturing_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="tujuanGudang"
                                            label="Gudang Tujuan"
                                            dense
                                            outlined
                                            hide-details
                                            :items="dataGudangTujuan"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="applyFilter"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="asalGudang"
                                            label="Gudang Asal"
                                            dense
                                            outlined
                                            hide-details
                                            :items="dataGudangAsal"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="applyFilter"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="detailTransaksi"
                                            label="Detail Transaksi"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listDetail"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="applyFilter"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0">
                                        <download-csv
                                            :data="dataJsonToCsv"
                                            name="Laporan Inbound.csv"
                                        >
                                            <v-btn
                                                color="#61B15A"
                                                dark
                                                class="mb-2 text-subtitle-1"
                                                style="text-transform: unset !important"
                                            >
                                                <v-icon left
                                                    >mdi-file-download</v-icon
                                                >
                                                Unduh Data
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <v-toolbar flat dense class="mb-5">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    autocomple="off"
                                    placeholder="Search..."
                                ></v-text-field>
                            </v-toolbar>
                            <div
                                class="d-flex mb-5 px-4 justify-space-between align-center"
                            >
                                <v-row>
                                    <v-col cols="2">
                                        <v-select
                                            v-model="itemsPerPage"
                                            :items="showPerPage"
                                            outlined
                                            dense
                                            label="items per page"
                                            v-on:change="changeItemPerPage"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col align="right">
                                        <span class="mr-15 text-caption"
                                            >Page: {{ page }}</span
                                        >
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="prevPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-left
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="nextPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-right
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | moment("YYYY/MM/DD") }}
                        </template>

                        <template v-slot:item.manufacturing_date="{ item }">
                            {{ item.manufacturing_date | moment("YYYY/MM/DD") }}
                        </template>

                        <template v-slot:item.expired_date="{ item }">
                            {{ item.expired_date | moment("YYYY/MM/DD") }}
                        </template>

                        <template v-slot:item.mc="{ item }">
                            {{ getValueOfQty(item.mc, "MC", item) }}
                        </template>
                        <template v-slot:item.ic="{ item }">
                            {{ getValueOfQty(item.ic, "IC", item) }}
                        </template>
                        <template v-slot:item.qr="{ item }">
                            {{ getValueOfQty(item.qr, "BTL", item) }}
                        </template>

                        <template v-slot:item.totBtl="{ item }">
                            {{ getTotalAll(item.mc, item.ic, item.qr) }}
                        </template>
                    </v-data-table>
                    <div
                        class="d-flex mt-5 px-4 justify-space-between align-center"
                    >
                        <v-row>
                            <v-col cols="2">
                                <v-select
                                    v-model="itemsPerPage"
                                    :items="showPerPage"
                                    outlined
                                    dense
                                    label="items per page"
                                    v-on:change="changeItemPerPage"
                                >
                                </v-select>
                            </v-col>
                            <v-col align="right">
                                <span class="mr-15 text-caption"
                                    >Page: {{ page }}</span
                                >
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="prevPage"
                                >
                                    <v-icon dark> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="nextPage"
                                >
                                    <v-icon dark> mdi-chevron-right </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
    data: () => ({
        search: "",
        loading: false,
        manufacturedDate: "",
        listManufacturedDate: [],
        expiredDate: "",
        listExpiredDate: [],
        listDetail: [
            { id: "", name: "semua transaksi" },
            { id: "HASIL_PRODUKSI", name: "Hasil Produksi" },
            { id: "ANTAR_GUDANG", name: "Antar Gudang" },
            { id: "RETUR", name: "Retur" },
        ],
        headers: [
            {
                text: "Detail Transaksi",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "name",
            },
            {
                text: "Asal Gudang",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "gudang_asal_name",
            },
            {
                text: "Surat Jalan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "nomer_seri",
            },
            {
                text: "Loading Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "created_at",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "MC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "mc",
            },
            {
                text: "IC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "ic",
            },
            {
                text: "Btl/Slv/Box",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qr",
            },
            {
                text: "Total Btl/Slv/Box",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "totBtl",
            },
            {
                text: "Manufacture Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "manufacturing_date",
            },
            {
                text: "Expired Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "expired_date",
            },
            {
                text: "Change By",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "scanner_name",
            },
        ],
        dataInbound: [],
        dataGudangAsal: [],
        asalGudang: "",
        dataGudangTujuan: [],
        tujuanGudang: "",
        detailTransaksi: "",
        expired_from: "",
        expired_to: "",
        manufacture_from: "",
        manufacture_to: "",
        dataJsonToCsv: [],
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        applyFilter() {
            this.loading = true;
            if (this.expiredDate !== "") {
                this.expired_from = this.expiredDate;
                this.expired_to =
                    this.expiredDate.substring(0, 10) + "T23:59:59.000Z";
            }
            if (this.manufacturedDate !== "") {
                this.manufacture_from = this.manufacturedDate;
                this.manufacture_to =
                    this.manufacturedDate.substring(0, 10) + "T23:59:59.000Z";
            }
            axios
                .get(
                    "v1/admin/laporan/flow/in/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&type=IN" +
                        "&gudang_asal_id=" +
                        this.asalGudang +
                        "&gudang_penerima_id=" +
                        this.tujuanGudang +
                        "&detail=" +
                        this.detailTransaksi +
                        "&expired_from=" +
                        this.expired_from +
                        "&expired_to=" +
                        this.expired_to +
                        "&manufacture_from=" +
                        this.manufacture_from +
                        "&manufacture_to=" +
                        this.manufacture_to
                )
                .then((response) => {
                    this.loading = false;
                    this.dataInbound = response.data.data;
                    this.unduhData();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.applyFilter();
                        }
                        console.clear();
                    }
                });
        },
        getGudang() {
            this.getGudangAsal();
            this.getGudangTujuan();
        },
        getDate() {
            this.getExpiredDate();
            this.getManufacturedDate();
        },
        async getGudangAsal() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.dataGudangAsal.push(defaultSelect);
            axios.get("v1/user/gudang/list?limit=100").then((response) => {
                response.data.data.forEach((v) => {
                    this.dataGudangAsal.push(v);
                });
            });
        },
        async getGudangTujuan() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.dataGudangTujuan.push(defaultSelect);
            this.user.listGudang.forEach((v) => {
                axios.get("v1/user/gudang?id=" + v).then((response) => {
                    if (response.data.data.is_activated === 1) {
                        this.dataGudangTujuan.push(response.data.data);
                    }
                    this.applyFilter();
                });
            });
        },
        async getExpiredDate() {
            const defaultSelect = { expired_date: "" };
            axios
                .get(
                    "/v1/admin/laporan/flow/in/exp/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&type=IN"
                )
                .then((response) => {
                    this.listExpiredDate = response.data.data;
                    this.listExpiredDate.push(defaultSelect);
                });
        },
        async getManufacturedDate() {
            const defaultSelect = { manufacturing_date: "" };
            axios
                .get(
                    "/v1/admin/laporan/flow/in/mfr/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&type=IN"
                )
                .then((response) => {
                    this.listManufacturedDate = response.data.data;
                    this.listManufacturedDate.push(defaultSelect);
                });
        },
        changeItemPerPage() {
            this.applyFilter();
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.applyFilter();
            }
        },
        nextPage() {
            if (this.dataBarcode === null) {
                return;
            } else {
                this.page = this.page + 1;
                this.applyFilter();
            }
        },
        next(page) {
            this.page = page;
            this.applyFilter();
        },

        unduhData() {
            axios
                .get(
                    "v1/admin/laporan/flow/in/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&type=IN" +
                        "&gudang_asal_id=" +
                        this.asalGudang +
                        "&gudang_penerima_id=" +
                        this.tujuanGudang +
                        "&detail=" +
                        this.detailTransaksi +
                        "&expired_from=" +
                        this.expired_from +
                        "&expired_to=" +
                        this.expired_to +
                        "&manufacture_from=" +
                        this.manufacture_from +
                        "&manufacture_to=" +
                        this.manufacture_to +
                        "&download=1"
                )
                .then((response) => {
                    var parseData = this.$papa.parse(response.data, {
                        header: true,
                    });
                    this.dataJsonToCsv = parseData.data;
                });
        },
    },
    created() {
        this.getGudang();
        this.getDate();
    },
};
</script>
